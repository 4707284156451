var md5 = require('md5');
export default class {
    // align print ct, lt, rt
    alignPrint(value) {
        return JSON.stringify({
            type: 'align',
            value
          })
    }

    // font print a, b
    fontPrint(value) {
        return JSON.stringify({
          type: 'font',
          value
        })
    }

    // size Print 
    sizePrint(value1, value2) {
        return JSON.stringify({
          type: 'size',
          value: [value1, value2]
        })
      }

      //text Print
      textPrint(value) {
        return JSON.stringify({
          type: 'text',
          value
        })
      }

      // space
      feedPrint (value) {
        return JSON.stringify({
          type: 'feed',
          value
        })
      }

      // QR Print
      qrPrint (qr, margin, size) {
        return JSON.stringify({
          type: 'qr',
          value: {
            code: qr,
            obj: {
              type: 'png',
              margin,
              size
            }
          }
        })
      }

      // cut print
      cutPrint (value, value2) {
        return JSON.stringify({
          type: 'cut',
          value: [value, value2]
        })
      }

      // potong lembar
      close () {
        return JSON.stringify({
          type: 'close'
        })
      }

      // style print
      stylePrint (value) {
        return JSON.stringify({
          type: 'style',
          value
        })
      }

      // Test Printer
        printServerTest() {
           const test = 'Test Printer'
           const arrPrint = [
            `${this.alignPrint('ct')}`,
            `${this.fontPrint('a')}`,
            `${this.sizePrint(2, 2.2)}`,
            `${this.textPrint(test)}`,
            `${this.cutPrint(1, 5)}`,
            `${this.close()}`
          ]
          const arrPrintParse = arrPrint.map(_item => JSON.parse(_item))
          return arrPrintParse
        }

        // printing pertama
        printServerNetwork ({nameRs, unit1,unit2,appointment, numberQueue, time, qrCode}) {
          const arrPrint = [
            `${this.alignPrint('ct')}`,
            `${this.fontPrint('a')}`,
            ...(nameRs ? [`${this.sizePrint(1, 1.2)}`, `${this.textPrint(nameRs)}`] : []),
            `${this.feedPrint('1')}`,
            `${this.sizePrint(0, 0.4)}`,
            ...(unit1 ? [`${this.textPrint(unit1)}`] : []),
            ...(unit2 ? [`${this.textPrint(unit2)}`] : []),
            ...(appointment ? [`${this.textPrint(appointment)}`] : []),
            `${this.feedPrint('2')}`,
            ...(numberQueue ? [ `${this.fontPrint('b')}`, `${this.sizePrint(12, 3)}`, `${this.textPrint(numberQueue)}`, `${this.feedPrint('2')}`] : []),
            `${this.feedPrint('2')}`,
            `${this.fontPrint('a')}`,
            `${this.sizePrint(0, 0.4)}`,
            `${this.textPrint(time)}`,
            `${this.textPrint('Mohon menunggu nomor Anda dipanggil')}`,
            `${this.feedPrint('1')}`,
            ...(qrCode ? [`${this.qrPrint(qrCode, 4, 4)}`] : []),
            `${this.feedPrint('1')}`,
            `${this.sizePrint(0, 0.1)}`,
            `${this.fontPrint('b')}`,
            `${this.textPrint('Scan QR ini dengan Aplikasi Care Dokter')}`,
            `${this.textPrint('Download di Google Play Store & Apple App Store')}`,
            `${this.feedPrint('1')}`,
            `${this.cutPrint(1, 5)}`,
            `${this.close()}`
          ]
          const arrPrintParse = arrPrint.map(_item => JSON.parse(_item))
          return arrPrintParse
        }

        // printer copies
        printCopies({unit1, unit2, appointment, numberQueue, time}) {
          const arrPrint = [
            `${this.alignPrint('ct')}`,
            `${this.fontPrint('a')}`,
            `${this.sizePrint(0, 0.4)}`,
            ...(unit1 ? [`${this.textPrint(unit1)}`] : []),
            ...(unit2 ? [`${this.textPrint(unit2)}`] : []),
            ...(appointment ? [`${this.textPrint(appointment)}`] : []),
            `${this.feedPrint('2')}`,
            ...(numberQueue ? [ `${this.fontPrint('b')}`, `${this.sizePrint(6, 3)}`, `${this.textPrint(numberQueue)}`, `${this.feedPrint('2')}`] : []),
            `${this.feedPrint('2')}`,
            `${this.fontPrint('a')}`,
            `${this.sizePrint(0, 0.4)}`,
            `${this.textPrint(time)}`,
            `${this.cutPrint(1, 5)}`,
            `${this.close()}`
          ]
          const arrPrintParse = arrPrint.map(_item => JSON.parse(_item))
          return arrPrintParse
        }

        keyUniquePrinter (address, port, secret, timeUtc) {
          const strAll = `${address}-${port}-${timeUtc}-${secret}`
          return md5(strAll)
        }
    
}